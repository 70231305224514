import React from "react"
import PropTypes from "prop-types";
import FooterItem from "./item"
import { StaticQuery, graphql } from "gatsby"

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            footer: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//footer//" } }
            ) {
              edges {
                node {
                  fields {
                    slug
                    source
                  }
                  frontmatter {
                    title
                  }
                }
              }
            }
            site {
              siteMetadata {
                authorNameShort
              }
            }
          }
        `}
        render={data => {
          const { theme } = this.props
          const {
            footer: { edges: footer },
            site
          } = data

          return (
            <React.Fragment>
              <footer className="footer">
                <div className="hosted">
                  <p>
                    Build with <span className="hosted-gatsby"><a href="https://www.gatsbyjs.org/">GatsbyJS</a></span>{" "}
                    and <span className="hosted-react"><a href="https://reactjs.org/">React</a></span>. Hosted on
                    Amazon S3. Brought to the edge with Cloudfront.
                  </p>
                </div>
                <ul className="FooterItemList">
                  {footer.map(footerItem => {
                    const {
                      node: {
                        fields: { slug },
                        frontmatter: { title },
                      },
                    } = footerItem

                    return <FooterItem to={slug} key={slug} label={title} theme={theme} />
                  })}
                </ul>
                <p className="copyright">{`© ${new Date().getFullYear()} ${site.siteMetadata.authorNameShort}`}</p>
              </footer>

              {/* --- STYLES --- */}
              <style jsx>{`
                .footer {
                  background-color: ${theme.color.neutral.blue};
                  margin-top: auto;
                }

                .footer :global(a),
                .footer :global(p) {
                  color: ${theme.color.footertext};
                }

                ul {
                  list-style: none;
                  text-align: center;
                  margin: 0 auto;
                  padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
                  max-width: ${theme.text.maxWidth.desktop};
                }

                .hosted,
                .copyright {
                  text-align: center;
                  margin: ${theme.space.default} auto;
                  height: 30px;
                  padding: ${theme.space.xs} ${theme.space.m};
                }

                .hosted-gatsby :global(a){
                  transition: all 0.3s ease 0s;
                }

                .hosted-react :global(a){
                  transition: all 0.3s ease 0s;
                }

                .hosted-gatsby :global(a: hover) {
                  color: #5c2e89;
                }

                .hosted-react :global(a: hover){
                  color: #62dafb;
                }

                
              `}</style>
            </React.Fragment>
          )
        }}
      />
    )
  }
}

Footer.propTypes = {
  theme: PropTypes.object.isRequired
}

export default Footer
