import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Item = props => {

    const { to, label, theme } = props;

    return (
        <React.Fragment>
            <li className="item" key={to}>
                <Link to={to} label={label}>{label}</Link>
            </li>

            {/* --- STYLES --- */}
            <style jsx>{`
                .item {
                    display: inline-block;
                    padding: 0px ${theme.space.default};
                    color: #fff;
                }

                .item :global(a) {
                    transition: all 0.3s ease 0s;
                }

                .item :global(a:hover) {
                    color: #fff;
                }
            `}</style>
        </React.Fragment>
    )
}

Item.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired
}


export default Item;