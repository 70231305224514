import React from "react";
import PropTypes from "prop-types";
import Item from "./item";
import { FaAngleDoubleLeft } from "react-icons/fa/";

class Menu extends React.Component {

    /* constructor(props) {
        super(props);
        this.itemListRef = React.createRef();
        this.expandButton = React.createRef();
    }

    componentDidMount() {
        this.expandButton.current.addEventListener('click', this.showMobileNavBar); 
      }

    componentWillUnmount() {
        this.expandButton.current.removeEventListener('click', this.showMobileNavBar);
    }

    showMobileNavBar = (event) => {
        this.itemListRef.current.classList.toggle('itemListActive');
    } */

    render() {

        const { theme, fixed } = this.props;

console.log(fixed);


        return (
            <React.Fragment>
                <nav className="menu">
                    <ul className="itemList" ref={this.itemListRef}>
                        <Item to="/" label="Home" theme={theme} />
                        <Item to="/blog/" label="Blog" theme={theme} />
                        <Item to="/gallery/" label="Gallery" theme={theme} />
                        <Item to="/about/" label="About" theme={theme} />
                    </ul>
                    {/* <div ref={this.expandButton} className="expandButton">
            <FaAngleDoubleLeft size={30} />
          </div> */}
                </nav>
                <div ref={this.expandButton} className="expandButton">
                        <FaAngleDoubleLeft size={30} />
                </div>
                {/* --- STYLES --- */}
                <style jsx>{`
                    
                    .menu {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                    }

                    .itemList {
                        width: 40%;
                        min-width: 450px;
                        list-style: none;
                        display: flex;
                        justify-content: space-between;

                    }

                    .expandButton {
                        visibility: hidden;
                        cursor: pointer;
                        color: ${theme.color.background.white_opac};
                        margin-right: 20px;
                    }

                    @media (max-width: 810px) {

                        :global(body) {
                            overflow-x: hidden;
                            position: relative;
                            
                        }
                        :global(html) {
                            overflow-x: hidden;
                            position: relative;
                        }

                        .itemList {
                            position: absolute;
                            right: 0px;
                            height: calc(100vh - (${fixed ? theme.header.height.fixed : theme.header.height.homepage}));
                            width: 100%;    
                            top: ${fixed ? theme.header.height.fixed : theme.header.height.homepage};
                            display: flex;
                            flex-direction: column;
                            background-color: ${theme.color.neutral.blue};
                            align-items: center;
                            justify-content: space-around;
                            transform: translateX(100%);
                            transition: transform 0.5s ease-in;
                            z-index: 1;
                            min-width: 300px;
                        }

                        .fixed {
                            height: calc(100vh - (${theme.header.height.fixed}));
                        }

                        .expandButton {
                            visibility: visible;
                            display: block;
                        }
                    }

                    .itemListActive {
                        transform: translateX(0%);
                    }
                    

                `}</style>
            </React.Fragment>
        )
    };
}

Menu.propTypes = {
    theme: PropTypes.object.isRequired
}

export default Menu;

//<div ref={elem => this.expandButton = elem} className="expandButton">