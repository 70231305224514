import React from "react"
import CookieConsent from "react-cookie-consent"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import BackgroundImage from "../components/background/background"
import ScrollUpButton from "react-scroll-up-button"
//import "./layout.module.css"

import themeObject from "../theme/theme.yaml"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        <BackgroundImage>
          <div className="container">
            <Header theme={themeObject} path="/" />
            <main>{children}</main>
            <Footer theme={themeObject} />
            <ScrollUpButton ContainerClassName="scrollUp" />
            <CookieConsent
              location="none"
              buttonText="Yes!"
              onAccept={() => {
                window.location.reload()
              }}
              cookieName="gatsby-gdpr-google-analytics"
              style={{ top: "50%", height: "100px", alignItems:"center", maxWidth: "700px"}}
            >
              This website uses cookies ... OK?
            </CookieConsent>

            {/* --- STYLES --- */}
            <style jsx global>{`
              .container {
                display: flex;
                flex-direction: column;
                min-height: 100vh;
              }

              html {
                box-sizing: border-box;
              }
              *,
              *:after,
              *:before {
                box-sizing: inherit;
                margin: 0;
                padding: 0;
              }
              body {
                font-family: "Open Sans", sans-serif;
              }
              h1,
              h2,
              h3 {
                font-weight: 600;
                line-height: 1.1;
                letter-spacing: -0.03em;
                margin: 0;
              }
              h1 {
                letter-spacing: -0.04em;
              }
              p {
                margin: 0;
              }
              strong {
                font-weight: 600;
              }
              a {
                text-decoration: none;
                color: ${themeObject.color.bodytext};
              }
              main {
                width: auto;
                display: block;
              }

              .scrollUp {
                background-color: transparent !important;
              }

              .scrollUp :global(svg) {
                fill: ${themeObject.color.neutral.category_blue}!important;
              }
            `}</style>
          </div>
        </BackgroundImage>
      </React.Fragment>
    )
  }
}

export default Layout
