import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import Menu from "../menu/menu"
//import avatar from "../../images/avatar/avatar_new_l.png"
import config from "../../../content/meta/config"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"

class Header extends React.Component {
  state = {
    fixed: false,
  }

  visibilitySensorChange = val => {
    if (val) {
      this.setState({ fixed: false })
    } else {
      this.setState({ fixed: true })
    }
  }

  getHeaderFixed = () => {
    return this.state.fixed ? "fixed" : ""
  }

  render() {
    const { theme } = this.props

    return (
      <StaticQuery
        query={graphql`
          query AvatarQuery {
            avatar: allImageSharp(filter: {fluid: {originalName: {eq: "avatar_new_l.png"}}}) {
              edges {
                node {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // const {
          //   avatar: { edges: { node } },
          // } = data

          return (
            <React.Fragment>
              <header className={`header ${this.getHeaderFixed()}`}>
                <Link to="/">
                  <div className="logoType">
                    <div className="logo">
                    <Img fluid={data.avatar.edges[0].node.fluid} alt={config.siteTitle} />
                    </div>
                    <div className="type">
                      <h1>{config.headerTitle}</h1>
                      <h2>{config.headerSubTitle}</h2>
                    </div>
                  </div>
                </Link>
                <Menu theme={theme} fixed={this.getHeaderFixed()} />
              </header>
              <VisibilitySensor onChange={this.visibilitySensorChange}>
                <div className="sensor" />
              </VisibilitySensor>

              {/* --- STYLES --- */}
              <style jsx>{`
                .header {
                  position: absolute;
                  width: 100%;
                  background-color: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  height: ${theme.header.height.homepage};
                }

                .fixed {
                  background-color: ${theme.color.neutral.blue};
                  height: ${theme.header.height.fixed};
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  position: fixed;
                  z-index: 10;
                  width: 100%;
                }

                .header :global(a),
                .fixed :global(a) {
                  color: ${theme.color.headertext};
                }

                .logoType {
                  display: flex;
                  flex-direction: row;
                  padding: 0px 20px;
                  min-width: 350px;
                }

                .logo {
                  cursor: pointer;
                  border-radius: 75% 75%;
                  border: 1px solid #eee;
                  width: 100%;
                  max-width: 60px;
                  overflow: hidden;
                }

                .type {
                  padding: 0px 20px;
                }

                h1 {
                  font-size: ${theme.font.size.m};
                  font-weight: ${theme.font.weight.standard};
                  margin: ${theme.space.stack.xs};
                  letter-spacing: 0.1rem;
                }

                h2 {
                  font-weight: ${theme.font.weight.standard};
                  font-size: ${theme.font.size.xxs};
                  letter-spacing: 0;
                  margin: 0;
                }

                .sensor {
                  display: block;
                  position: absolute;
                  bottom: 0;
                  z-index: 1;
                  left: 0;
                  right: 0;
                  height: 1px;
                  top: ${theme.header.height.homepage};
                }

                .fixed :global(h2) {
                  display: none;
                }

                .fixed .logo {
                  height: 36px;
                  width: 36px;
                }

                @media (max-width: 400px) {
                  .logoType {
                    min-width: 280px;
                    padding: 0 0 0 20px;
                  }

                  h2 {
                    font-size: ${theme.font.size.xxxs};
                  }
                }
              `}</style>
            </React.Fragment>
          )
        }}
      />
    )
  }
}

Header.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default Header

/*
<style jsx="true">{`
                    .header {
                        align-items: center;
                        justify-content: center;
                        background-color: ${theme.color.neutral.white};
                        display: flex;
                        top: 0;
                        width: 100%;
                        align-items: center;
                        position: fixed;
                        height: ${theme.header.height.homepage};
                        z-index:1

                        
                    }

                    @from-width 768px {
                        .header {
                            position: absolute;
                        }
                    }


                    h1 {
                        font-size: ${theme.font.size.m};
                        font-weight: ${theme.font.weight.standard};
                        margin: ${theme.space.stack.xs};
                    }
            
                    h2 {
                        font-weight: ${theme.font.weight.standard};
                        font-size: ${theme.font.size.xxs};
                        letter-spacing: 0;
                        margin: 0;
                    }

                    .logo {
                        border-radius: 65% 75%;
                        border: 1px solid #eee;
                        display: inline-block;
                        height: 44px;
                        margin: ${theme.space.inline.default};
                        overflow: hidden;
                        width: 44px;
                        transition: all 0.5s;
                        height: 60px;
                        width: 60px;
                    }
                `}</style>
                */
