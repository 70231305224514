import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Item = props => {

    const { theme } = props;

    return (
        <React.Fragment>
            <li className="item" key={props.label}>
                <Link to={props.to}>
                    {props.label}
                </Link>
            </li>

            {/* --- STYLES --- */}
            <style jsx>{`
                .item {
                    display: inline-block;
                    padding: 0px 20px;
                }

                .item :global(a) {
                    transition: all 0.3s ease 0s;
                    text-transform: uppercase;
                    letter-spacing: 0.3rem;
                }

                .item :global(a:hover) {
                    color: ${theme.color.neutral.white};
                }

                
            `}</style>
        </React.Fragment>
    )
}

Item.propTypes = {
    theme: PropTypes.object.isRequired
}


export default Item;

/*
@media (max-width: 700px) {
                    .item {
                        margin: auto;
                    }
                }
                */