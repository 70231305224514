module.exports = {
  siteTitle: "Theflokster.com - Another personal blog", // <title>
  siteHeadline: "Another personal blog",
  shortSiteTitle: "Theflokster", // <title> ending for posts and pages
  siteDescription:
    "Theflokster.com - A personal blog and photo gallery. I'm passionate about technology, development, photography and sports. With that blog I want to share some insights and projects which might be of interest to you as well.",
  siteUrl: "https://theflokster.com",
  siteLanguage: "en",

  /* info */
  headerTitle: "the Flokster",
  headerSubTitle: "presenting another personal blog",
  defaultImage: "/preview.png",

  // social
  authorName: "Philipp Stotz",
  authorNameShort: "Philipp",
  authorSocialLinks: {
    "github" : { user: "stotz89", url: "https://github.com/stotz89" },
    "twitter": { user: "@p_stotz", url: "https://twitter.com/p_stotz" },
    "Instagram": { user: "phi_sto", url: "https://www.instagram.com/phi_sto/" },
    "Medium": { user: "p_stotz", url: "https://www.medium.com/@p_stotz" }
  },
}
