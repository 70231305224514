import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: imageSharp(
          fluid: { originalName: { regex: "/hero-background/" } }
        ) {
          fluid(maxWidth: 1900, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const BgImageDataStack = [
        `linear-gradient(
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)
          )`,
        data.desktop.fluid,
      ]
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={BgImageDataStack}
        >
            {children}
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
`

export default StyledBackgroundSection
