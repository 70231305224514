import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import config from "../../../content/meta/config"

const SEO = ({ title, description, image, article, dateCreated, dateModified }) => {
  var time = new Date()
  time = time.toISOString()
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            defaultImage
          }
        }
      }
    `
  )

  const seo = {
    siteLanguage: site.language || `en`,
    title: title || site.siteMetadata.siteTitle,
    siteHeadline: config.siteHeadline || "Headline",
    description: description || site.siteMetadata.description,
    image: `${site.siteMetadata.siteUrl}${image ||
      site.siteMetadata.defaultImage}`,
    baseUrl: site.siteMetadata.siteUrl || "https://theflokster.com",
    siteUrl: `${site.siteMetadata.siteUrl ||
      "https://theflokster.com"}${pathname}`,
    type: article ? "article" : "website",
    twitterAccount: config.authorTwitterAccount || "",
    authorName: config.authorName || "Philipp Stotz",
    dateCreated: dateCreated || '2020-02-20T10:30:00+01:00',
    dateModified: dateModified || time
  }
  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: seo.baseUrl,
    headline: seo.siteHeadline,
    inLanguage: seo.siteLanguage,
    mainEntityOfPage: seo.baseUrl,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Person",
      name: seo.authorName,
    },
    copyrightHolder: {
      "@type": "Person",
      name: seo.authorName,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: seo.authorName,
    },
    publisher: {
      "@type": "Person",
      name: seo.authorName,
    },
    datePublished: seo.dateCreated,
    dateModified: seo.dateModified,
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": seo.baseUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: seo.authorName,
      },
      copyrightHolder: {
        "@type": "Person",
        name: seo.authorName,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Person",
        name: seo.authorName,
      },
      publisher: {
        "@type": "Person",
        name: seo.authorName,
      },
      datePublished: seo.dateCreated,
      dateModified: seo.dateModified,
      description: seo.description,
      headline: seo.title,
      inLanguage: seo.siteLanguage,
      url: seo.siteUrl,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.siteUrl,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.siteUrl,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
      }}
      title={seo.title}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `image`,
          content: seo.image,
        },
        //OpenGraph Tags
        {
          name: `og:url`,
          content: seo.siteUrl,
        },
        {
          name: `og:title`,
          content: seo.title,
        },
        {
          name: `og:description`,
          content: seo.description,
        },
        {
          name: `og:image`,
          content: seo.image,
        },
        {
          name: `og:type`,
          content: seo.type,
        },
        //Twitter Tags
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:creator`,
          content: seo.twitterAccount,
        },
        {
          name: `twitter.title`,
          content: seo.title,
        },
        {
          name: `twitter.description`,
          content: seo.description,
        },
        {
          name: `twitter.image`,
          content: seo.image,
        },
      ]}
    >
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
